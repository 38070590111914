import LoaderComponent from 'react-loader-spinner';
import React, { FC } from 'react';
import styled from 'styled-components';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { colors } from '../../theme';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface LoaderProps {
  width?: number;
  heigth?: number;
}

export const Loader: FC<LoaderProps> = ({ width = 100, heigth = 100 }) => (
  <LoaderWrapper>
    <LoaderComponent
      type="MutatingDots"
      color={colors.red}
      height={heigth}
      width={width}
    />
  </LoaderWrapper>
);
