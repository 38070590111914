import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FaInstagram, FaFacebookF, FaStrava } from 'react-icons/fa';
import moment from 'moment';

import api from '../services';
import { ContainerBasedOnPaddings } from '../ui';
import { Loader } from '../components/common/loader';
import { Header } from '../components/common/header';
import { Footer } from '../components/common/footer';

import { SectionTitle } from '../components/common/section-title';
import { UserPhoto } from '../components/common/photo';
import { pages } from '../components/navigation/navigationList';
import { breakpoints } from '../ui/breakpoints';
import { Race } from '../components/user-info/race';

moment.locale('ru');

interface Result {
  distance: string;
  time: string;
}

interface RaceResult extends Result {
  name: string;
  place: string;
  date: string;
}

interface UserInfoBase {
  fullname: string;
  birthday: string;
  city: string;
  startRunning: string;
  instalink: string;
  fblink: string;
  stravalink: string;
  teamname: string;
  kilometersOverall: number;
  kilometersThisMonth: number;
  avatar?: string;
  personalBests: Result[];
  raceResults: RaceResult[];
}

type UserInfo = UserInfoBase | undefined;

const UserInfoContainer = styled(ContainerBasedOnPaddings)`
  margin-top: 5rem;
  margin-bottom: 5rem;
`;
const UserInfoWrapper = styled.main`
  flex-grow: 1;
`;
const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;

  > span:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
const Title = styled.h2`
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 1rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    text-align: center;
  }
`;

const MainInfo = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints.m}px) {
    flex-direction: column;
  }
`;
const MainInfoLeft = styled.div`
  display: flex;

  @media screen and (max-width: ${breakpoints.m}px) {
     justify-content: space-between;
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    flex-direction: column;
    align-items: center;
  }
`;
const MainInfoRight = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.m}px) {
    margin-top: 3rem;
  }
`;

const UserMainInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    margin-left: 0;
    margin-top: 2rem;
  }
`;

const PersonalBestsWrapper = styled.ul``;
const PersonalBest = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.black};
  }
`;
const PhotoAndSocialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SocialsList = styled.ul`
  display: flex;
  margin-top: 2rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    justify-content: center;
  }
`;
const SocialsListItem = styled.li`
  margin-right: 2rem;
`;
const SocialsListLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.black};
  color: ${({ theme: { colors } }) => colors.white};
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  transition: ${({ theme: { transition } }) => transition};

  &:hover {
    color: ${({ theme: { colors } }) => colors.red};
  }
`;
const PersonalInfo = styled.span`
  color: ${({ theme: { colors } }) => colors.grayii};
  font-size: 1.6rem;
  line-height: 1;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
const Distance = styled.span``;
const Time = styled.span``;
const SecondaryInfo = styled.div`
  display: flex;
  margin-top: 9rem;

  @media screen and (max-width: ${breakpoints.xs}px) {
    flex-direction: column;
    margin-top: 4rem;
  }
`;
const SecondaryInfoBlock = styled.div`
  border-left: 10px solid ${({ theme: { colors } }) => colors.yellow};
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  &:not(:last-child) {
    margin-right: 7rem;
  }

  @media screen and (max-width: ${breakpoints.xs}px) {
    &:not(:last-child) {
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }
`;
const SecondaryInfoBlockTitle = styled.h3`
  font-weight: normal;
  margin-bottom: 1rem;
  font-size: 2.4rem;
`;
const SecondaryInfoBlockValue = styled.span`
  font-weight: bold;
  font-size: 3rem;
`;
const RaceResults = styled(ContainerBasedOnPaddings)`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.m}px) {
    max-width: 100%;
  }
`;
const RacesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
`;

export const UserInfo: FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [loaded, setLoaded] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (loaded) {
      api.getUserInfo(id).then(({ data }) => {
        setUserInfo(data);
        setLoaded(false);
      });
    }
  }, [loaded, userInfo, id]);

  const showPersonalRecords = () => {
    const filteredPRs = userInfo
      ? userInfo.personalBests.filter((pr: Result) => pr.time !== '00:00')
      : [];

    return (
      <>
        <Title>Личные рекорды</Title>
        <PersonalBestsWrapper>
          {filteredPRs.length !== 0 ? (
            filteredPRs.map((pr: Result, index) => (
              <PersonalBest key={index}>
                <Distance>{pr.distance}</Distance> <Time>{pr.time}</Time>
              </PersonalBest>
            ))
          ) : (
            <span>Отсутствуют</span>
          )}
        </PersonalBestsWrapper>
      </>
    );
  };

  const showSecondaryPersonInfo = () => {
    const { kilometersOverall, teamname } = userInfo!;
    return (
      <SecondaryInfo>
        {kilometersOverall !== undefined && (
          <SecondaryInfoBlock>
            <SecondaryInfoBlockTitle>Всего километров</SecondaryInfoBlockTitle>
            <SecondaryInfoBlockValue>
              {kilometersOverall}
            </SecondaryInfoBlockValue>
          </SecondaryInfoBlock>
        )}
        {teamname && (
          <SecondaryInfoBlock>
            <SecondaryInfoBlockTitle>Команда</SecondaryInfoBlockTitle>
            <SecondaryInfoBlockValue>{teamname}</SecondaryInfoBlockValue>
          </SecondaryInfoBlock>
        )}
      </SecondaryInfo>
    );
  };

  const showRaceResults = () => {
    if (userInfo && userInfo.raceResults.length !== 0) {
      const { raceResults } = userInfo;

      return (
        <>
          <SectionTitle>Забеги</SectionTitle>
          <RaceResults>
            <RacesWrapper>
              {raceResults.map((raceResult: RaceResult) => (
                <Race raceInfo={raceResult} />
              ))}
            </RacesWrapper>
          </RaceResults>
        </>
      );
    }
    return null;
  };

  const getUserInfo = () => {
    if (userInfo && !loaded) {
      const {
        fullname,
        birthday,
        city,
        startRunning,
        instalink,
        fblink,
        stravalink,
        avatar
      } = userInfo;

      const startRunningDate = moment(startRunning).format('MM/DD/YYYY');
      const birthDay = moment(birthday).format('MM/DD/YYYY');

      return (
        <InfoWrapper>
          <MainInfo>
            <MainInfoLeft>
              <PhotoAndSocialsWrapper>
                <UserPhoto avatar={avatar} />
                <SocialsList>
                  {instalink && (
                    <SocialsListItem>
                      <SocialsListLink target="_blank" href={instalink}>
                        <FaInstagram />
                      </SocialsListLink>
                    </SocialsListItem>
                  )}
                  {fblink && (
                    <SocialsListItem>
                      <SocialsListLink target="_blank" href={fblink}>
                        <FaFacebookF />
                      </SocialsListLink>
                    </SocialsListItem>
                  )}
                  {stravalink && (
                    <SocialsListItem>
                      <SocialsListLink target="_blank" href={stravalink}>
                        <FaStrava />
                      </SocialsListLink>
                    </SocialsListItem>
                  )}
                </SocialsList>
              </PhotoAndSocialsWrapper>
              <UserMainInfo>
                <Title>{fullname}</Title>
                {birthday && <PersonalInfo>{birthDay}</PersonalInfo>}
                {city && <PersonalInfo>Украина, {city}</PersonalInfo>}
                {startRunning && (
                  <PersonalInfo>Бегает с {startRunningDate}</PersonalInfo>
                )}
              </UserMainInfo>
            </MainInfoLeft>
            <MainInfoRight>{showPersonalRecords()}</MainInfoRight>
          </MainInfo>
          {showSecondaryPersonInfo()}
        </InfoWrapper>
      );
    } else {
      return <Loader />;
    }
  };

  return (
    <>
      <Header activePage={pages.ALL_USERS} />
      <UserInfoWrapper>
        <UserInfoContainer>{getUserInfo()}</UserInfoContainer>
      </UserInfoWrapper>
      {showRaceResults()}
      <Footer />
    </>
  );
};
