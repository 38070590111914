import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { uiTheme as theme } from './theme';
import { GlobalStyle } from './ui';

import { MainPage } from './pages/main';
import { AllUsers } from './pages/all-users';
import { AboutUs } from './pages/about-us';
import { UserInfo } from './pages/user-info';

export const App: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Route path="/" exact component={MainPage} />
        <Route path="/about" component={AboutUs} />
        <Route path="/users/:id/info" component={UserInfo} />
        <Route path="/users/all" component={AllUsers} />
      </Router>
    </ThemeProvider>
  );
};
