import axios from 'axios';

const url = 'https://squad-runners.in.ua/api';

export default {
  getAllMembers() {
    return axios.get(`${url}/users/all`);
  },
  getTopMembers() {
    return axios.get(`${url}/users/top`);
  },
  getUserInfo(id) {
    return axios.get(`${url}/users/${id}/info`);
  },
  getRaces() {
    return axios.get(`${url}/races`);
  },
  getNews() {
    return axios.get(`${url}/news`);
  },
  getMetrics() {
    return axios.get(`${url}/metrics`);
  },
  getInstagramPhotos() {
    return axios.get(`${url}/instagram`);
  }
};
