import React, { FC } from 'react';

import { Hero } from '../components/main/hero';
import { Footer } from '../components/common/footer';
import { Header } from '../components/common/header';
import { RaceDetails } from '../components/main/racesDetails';
import { Metrics } from '../components/main/metrics';
import { Media } from '../components/main/media';
import { TopUsers } from '../components/main/topUsers';
import { pages } from '../components/navigation/navigationList';

export const MainPage: FC = () => {
  return (
    <>
      <Header activePage={pages.MAIN} />
      <Hero />
      <RaceDetails />
      <Metrics />
      <TopUsers />
      <Media />
      <Footer />
    </>
  );
};
