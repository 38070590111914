import React, { useState, useEffect, useRef, FC } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  useWindowResize,
  useThrottledFn,
  Cancelable
} from 'beautiful-react-hooks';

import { breakpoints } from '../../ui/breakpoints';

const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  margin-bottom: 2rem;

  background-color: ${props => props.theme.colors.black};
  width: 100%;

  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 3rem;
    flex-wrap: wrap;
  }
`;

const CountdownItem = styled.div`
  color: var(--color-text);
  font-size: 5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 150px;
  height: 150px;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 3rem;
    width: 100%;
    height: 100px;
  }
`;

const CountdownSvg = styled.svg`
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  left: 50%;
  width: 120px;
  height: 120px;

  @media (max-width: ${breakpoints.s}px) {
    width: 90px;
    height: 90px;
  }
`;

const DataWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 2rem;
    width: 50%;
  }
`;

const TimeLabel = styled.span`
  color: var(--color-text);
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 300;

  @media (max-width: ${breakpoints.s}px) {
    font-weight: 1.6rem;
  }
`;

interface CountdownProps {
  timeTillDate: string;
  timeFormat: string;
}

export const Countdown: FC<CountdownProps> = ({ timeTillDate, timeFormat }) => {
  const [days, setDays] = useState<string>('');
  const [hours, setHours] = useState<string>('');
  const [minutes, setMinutes] = useState<string>('');
  const [seconds, setSeconds] = useState<string>('');
  const [width, setWidth] = useState(window.innerWidth);
  const [isMobileView, setMobileView] = useState(false);

  const onWindowResizeHandler: Cancelable & Function = useThrottledFn(() =>
    setWidth(window.innerWidth)
  ) as Cancelable & Function;

  useWindowResize(onWindowResizeHandler);

  useEffect(() => setMobileView(width < breakpoints.s), [setMobileView, width]);

  const interval = useRef(-1);

  useEffect(() => {
    interval.current = setInterval(() => {
      const then: any = moment(timeTillDate, timeFormat);
      const now: any = moment();

      const countdown = moment(then - now);

      const days = countdown.format('D');
      const hours = countdown.format('HH');
      const minutes = countdown.format('mm');
      const seconds = countdown.format('ss');
      const months = countdown.format('MM');

      const totalDays = parseInt(days, 10) * parseInt(months, 10);
      setDays(totalDays.toString());
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }, 1000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [timeFormat, timeTillDate]);

  const daysRadius = mapNumber(Number(days), 30 * 12, 0, 0, 360);
  const hoursRadius = mapNumber(Number(hours), 24, 0, 0, 360);
  const minutesRadius = mapNumber(Number(minutes), 60, 0, 0, 360);
  const secondsRadius = mapNumber(Number(seconds), 60, 0, 0, 360);

  const getCountDownItem = (radius: number, data: number, label: string) => (
    <DataWrapper>
      <CountdownItem>
        <SVGCircle radius={radius} isMobileView={isMobileView} />
        {data}
      </CountdownItem>
      <TimeLabel>{label}</TimeLabel>
    </DataWrapper>
  );

  return (
    <CountdownWrapper>
      {getCountDownItem(daysRadius, Number(days), 'Дней')}
      {getCountDownItem(hoursRadius, Number(hours), 'Часов')}
      {getCountDownItem(minutesRadius, Number(minutes), 'Минут')}
      {getCountDownItem(secondsRadius, Number(seconds), 'Секунд')}
    </CountdownWrapper>
  );
};

interface SVGCircleProps {
  radius: number;
  isMobileView: boolean;
}

const SVGCircle: FC<SVGCircleProps> = ({ radius, isMobileView }) => {
  const actualRadius = isMobileView ? 35 : 55;
  const actualPosition = isMobileView ? 45 : 60;
  const actualUnderlayStrokeWidth = isMobileView ? 1 : 2;
  const actualOverlayStrokeWidth = isMobileView ? 4 : 10;

  return (
    <CountdownSvg>
      <path
        fill="none"
        stroke="#777"
        strokeWidth={actualUnderlayStrokeWidth}
        strokeLinejoin="round"
        d={describeArc(actualPosition, actualPosition, actualRadius, 0, 359.9)}
      />
      <path
        fill="none"
        stroke="#ffe928"
        strokeWidth={actualOverlayStrokeWidth}
        strokeLinejoin="round"
        d={describeArc(actualPosition, actualPosition, actualRadius, 0, radius)}
      />
    </CountdownSvg>
  );
};

// From stackoverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians)
  };
};

const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y
  ].join(' ');

  return d;
};

// Stackoverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
const mapNumber = (
  number: number,
  in_min: number,
  in_max: number,
  out_min: number,
  out_max: number
) => {
  return (
    ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
  );
};
