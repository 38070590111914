import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import renderHTML from 'react-render-html';
import { breakpoints } from '../../ui/breakpoints';
import { animateScroll } from 'react-scroll';
import {SCROLL_CONFIGURATION} from "../../constants";

interface LogoProps {
  /**
   * True if user scrolled page
   */
  isScrolled: boolean;
  /**
   * Hide text
   */
  hideText?: boolean;
}

const LogoWrapper = styled.a<LogoProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  > svg {
    height: 100px;

    .filler {
      fill: ${props =>
        props.isScrolled
          ? props.theme.colors.yellow
          : props.theme.colors.black};
    }

    ${props =>
      props.hideText &&
      css`
        .squad,
        .runners {
          display: none;
        }
      `};

    @media (max-width: ${breakpoints.s}px) {
      height: 100px;
    }
  }
`;

const SquadLogo = `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 841.9 595.3" style="enable-background:new 0 0 3000 2000;" xml:space="preserve">
<g>
	<path class="filler" d="M173.9,312.9c8.7,2.6,24.5,2.6,24.5,2.6s-1.7-13.7-8.9-16.3c-13.8-5.1-30.9-7.4-30.9-7.4
		S165.2,310.3,173.9,312.9z"/>
	<path class="filler" d="M340,319.9c0,0-8-40.2-36.7-61.6c5.7-35.6-6.9-68.9-35.2-90.3c0,0-36.4,58.6-42.9,119s-6.1,71.8-6.1,71.8
		s10.3-1,13,1.7s0,7.3-18.4,15.7c-11.9-5.4-18.2-11.2-19.8-8.2c-1.5,3.1-1.7,5.1,5.1,11.5c7.5,7,17.7,12.2,17.7,12.2
		s0.3,20.6-9.2,20.6c-7.3,0-12.4-3.2-14.6-7.4c-3.8-7.3-9.4-24.7-23.3-46.4c-14.9-23.2-34.8-37.4-34.8-37.4s6.1-8.8,16.5-15.7
		c-10.7-2.7-25.3-4.2-25.3-4.2s4.6-13,18-31.9c13.4-18.9,27.6-31.2,27.6-31.2s0.4-9.6-2.7-33.7c-3.1-24.1-9.6-36.4-9.6-36.4
		c-28.3,21.4-41,54.7-35.2,90.3c-28.7,21.4-36.7,61.6-36.7,61.6s7.7-4.2,18.8-2.7c-4.2,4.6-5.7,11.1-5.7,11.1s25.3,1.1,45.2,23
		s27.9,40.6,36,58.2c8,17.6,32.2,15.7,32.2,15.7s24.1,1.9,32.2-15.7c8-17.6,16.1-36.4,36-58.2c19.9-21.8,45.2-23,45.2-23
		s-1.5-6.5-5.7-11.1C332.3,315.7,340,319.9,340,319.9z M253.5,312.9c-8.7,2.6-24.5,2.6-24.5,2.6s1.7-13.7,8.9-16.3
		c13.8-5.1,30.9-7.4,30.9-7.4S262.2,310.3,253.5,312.9z"/>
</g>
<g class="squad">
	<path class="filler" d="M400.7,206c19.8,0,29.9,11.8,29.9,32.5v4.1h-19.2v-5.4c0-9.2-3.7-12.7-10.2-12.7s-10.2,3.5-10.2,12.7
		c0,26.6,39.7,31.6,39.7,68.5c0,20.7-10.3,32.5-30.3,32.5s-30.3-11.8-30.3-32.5v-7.9h19.2v9.2c0,9.2,4.1,12.6,10.5,12.6
		s10.5-3.3,10.5-12.6c0-26.6-39.7-31.6-39.7-68.5C370.8,217.9,381,206,400.7,206z"/>
	<path class="filler" d="M450.1,238.5c0-20.7,10.9-32.5,30.9-32.5s30.9,11.8,30.9,32.5v67.2c0,7.2-1.3,13.3-3.9,18.1
		c0.9,2.4,2.4,2.8,5.7,2.8h1.8v18.1h-2.8c-9.1,0-14.8-3.3-17.6-8.9c-4.1,1.5-8.9,2.4-14.2,2.4c-20,0-30.9-11.8-30.9-32.5V238.5z
		 M470.4,307.1c0,9.2,4.1,12.7,10.5,12.7s10.5-3.5,10.5-12.7v-69.8c0-9.2-4.1-12.7-10.5-12.7s-10.5,3.5-10.5,12.7V307.1z"/>
	<path class="filler" d="M553.5,207.5v99.8c0,9.2,4.1,12.6,10.5,12.6s10.5-3.3,10.5-12.6v-99.8h19.2V306c0,20.7-10.3,32.5-30.3,32.5
		c-20,0-30.3-11.8-30.3-32.5v-98.5H553.5z"/>
	<path class="filler" d="M682.6,336.8h-20.5l-3.5-23.5h-24.9l-3.5,23.5h-18.7l20.7-129.3H662L682.6,336.8z M636.3,295.8h19.6l-9.8-65.4
		L636.3,295.8z"/>
	<path class="filler" d="M701.3,207.5h32.1c20.3,0,30.3,11.3,30.3,32v65.4c0,20.7-10,32-30.3,32h-32.1V207.5z M721.6,226v92.4h11.5
		c6.5,0,10.3-3.3,10.3-12.6v-67.2c0-9.2-3.9-12.6-10.3-12.6H721.6z"/>
</g>
<g class="runners">
	<path class="filler" d="M380.8,348.3v51.6c0,4.8,2.1,6.5,5.4,6.5s5.4-1.7,5.4-6.5v-51.6h9.9v50.9c0,10.7-5.3,16.8-15.7,16.8
		s-15.7-6.1-15.7-16.8v-50.9H380.8z"/>
	<path class="filler" d="M447.6,415.2H437l-1.8-12.1h-12.9l-1.8,12.1h-9.6l10.7-66.9h15.4L447.6,415.2z M423.6,394h10.1l-5.1-33.8
		L423.6,394z"/>
	<path class="filler" d="M498.8,415.2c-0.6-1.7-1-2.8-1-8.2v-10.5c0-6.2-2.1-8.5-6.9-8.5h-3.6v27.2h-10.5v-66.9h15.9
		c10.9,0,15.6,5.1,15.6,15.4v5.3c0,6.9-2.2,11.4-6.9,13.6c5.3,2.2,7,7.3,7,14.2v10.3c0,3.2,0.1,5.6,1.1,8.1H498.8z M487.3,357.9
		v20.5h4.1c3.9,0,6.3-1.7,6.3-7.1v-6.6c0-4.8-1.6-6.9-5.3-6.9H487.3z"/>
	<path class="filler" d="M530,348.3v51.6c0,4.8,2.1,6.5,5.4,6.5s5.4-1.7,5.4-6.5v-51.6h9.9v50.9c0,10.7-5.3,16.8-15.7,16.8
		s-15.7-6.1-15.7-16.8v-50.9H530z"/>
	<path class="filler" d="M572.1,366.8v48.4h-9.5v-66.9h13.2l10.8,40v-40h9.4v66.9h-10.8L572.1,366.8z"/>
	<path class="filler" d="M617.7,366.8v48.4h-9.5v-66.9h13.2l10.8,40v-40h9.4v66.9h-10.8L617.7,366.8z"/>
	<path class="filler" d="M664.4,376.5h14.4v9.6h-14.4v19.6h18.1v9.6h-28.7v-66.9h28.7v9.6h-18.1V376.5z"/>
	<path class="filler" d="M715.8,415.2c-0.6-1.7-1-2.8-1-8.2v-10.5c0-6.2-2.1-8.5-6.9-8.5h-3.6v27.2h-10.5v-66.9h15.9
		c10.9,0,15.6,5.1,15.6,15.4v5.3c0,6.9-2.2,11.4-6.9,13.6c5.3,2.2,7,7.3,7,14.2v10.3c0,3.2,0.1,5.6,1.1,8.1H715.8z M704.3,357.9
		v20.5h4.1c3.9,0,6.3-1.7,6.3-7.1v-6.6c0-4.8-1.6-6.9-5.3-6.9H704.3z"/>
	<path class="filler" d="M751.1,347.6c10.2,0,15.5,6.1,15.5,16.8v2.1h-9.9v-2.8c0-4.8-1.9-6.6-5.3-6.6s-5.3,1.8-5.3,6.6
		c0,13.8,20.5,16.3,20.5,35.4c0,10.7-5.3,16.8-15.7,16.8s-15.7-6.1-15.7-16.8V395h9.9v4.8c0,4.8,2.1,6.5,5.4,6.5s5.4-1.7,5.4-6.5
		c0-13.8-20.5-16.3-20.5-35.4C735.7,353.7,740.9,347.6,751.1,347.6z"/>
</g>
</svg>
`;

export const Logo: FC<LogoProps> = ({ isScrolled, hideText }) => {
  const onClickHandler = () => animateScroll.scrollToTop(SCROLL_CONFIGURATION);

  return (
    <LogoWrapper isScrolled={isScrolled} hideText={hideText} onClick={onClickHandler}>
      {renderHTML(SquadLogo)}
    </LogoWrapper>
  );
};

LogoWrapper.defaultProps = {
  hideText: false
};
