import React, { useState, useEffect, FC } from 'react';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { FaInstagram } from 'react-icons/fa';

import { COMMON_SLIDER_SETTINGS } from '../../constants';
import { ContainerBasedOnPaddings } from '../../ui/common';
import api from '../../services';

import { InstaPost } from './InstaPost';
import { Loader } from '../common/loader';
import { SectionTitle } from '../common/section-title';

interface InstaContent {
  instaId: string;
  mediaUrl: string;
  timestamp: string;
  permalink: string;
}

const Content = styled(ContainerBasedOnPaddings)<{ uiLoading: boolean }>`
  min-height: 300px;
  margin-bottom: 5rem;

  ${props =>
    props.uiLoading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const Media: FC = () => {
  const [instaFeed, setInstaFeed] = useState<InstaContent[] | undefined>();
  const [loaded, setLoaded] = useState(false);

  const PHOTOS_TO_SHOW = 15;
  const settings = {
    ...COMMON_SLIDER_SETTINGS,
    slidesToShow: 3,
    slidesToScroll: 3
  };

  useEffect(() => {
    api
      .getInstagramPhotos()
      .then(res => {
        const { data } = res;
        data.length = PHOTOS_TO_SHOW;

        setLoaded(true);
        setInstaFeed(data);
      })
      .catch(e => console.log(e));
  }, []);

  const formatInstaPosts = () =>
    instaFeed &&
    instaFeed.map(({ instaId, permalink, mediaUrl }) => (
      <InstaPost key={instaId} url={mediaUrl} link={permalink} />
    ));

  return (
    <Wrapper>
      <SectionTitle direction="ltr">
        <FaInstagram />
      </SectionTitle>
      <Content id="media" uiLoading={!loaded}>
        {!loaded && !instaFeed ? (
          <Loader />
        ) : (
          <Slider {...settings}>{formatInstaPosts()}</Slider>
        )}
      </Content>
    </Wrapper>
  );
};
