import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header } from '../components/common/header';
import { Footer } from '../components/common/footer';
import { Button, ContainerBasedOnPaddings } from '../ui';
import { breakpoints } from '../ui/breakpoints';
import { FaTelegramPlane, FaFacebookF } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import api from '../services';

import { UserPhoto } from '../components/common/photo';
import { TeamMember } from '../components/about-us/TeamMember';
import { SectionTitle } from '../components/common/section-title';
import { pages } from '../components/navigation/navigationList';

import Godun from '../assets/images/team/godun.jpg';
import Captain from '../assets/images/team/captain.jpg';
import Rogovoy from '../assets/images/team/rogovoy.jpg';
import Gordienko from '../assets/images/team/gordienko.jpg';
import Medvedeva from '../assets/images/team/medvedeva.jpg';
import Evgeniya from '../assets/images/team/evgeniya.jpg';
import { MetricsData } from '../components/main/metrics';

const MainContentWrapper = styled.main`
  flex-grow: 1;
  margin-top: 2rem;
`;

const MainContentContainer = styled(ContainerBasedOnPaddings)``;

const Title = styled(SectionTitle)`
  margin-top: 3rem;
`;

const TeamTitle = styled.h2`
  margin-bottom: 4rem;
`;

const TeamContainer = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const AboutUsWrapper = styled.section`
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    flex-direction: column;
    margin-bottom: 4rem;
  }
`;

const AboutUsMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: ${breakpoints.s}px) {
    align-items: center;
  }
`;

const NameCaption = styled.h3`
  margin-bottom: 2rem;
  margin-top: 0;
`;

const AboutUsSidebar = styled.aside`
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    margin-left: 0;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 2rem;
  margin-top: 0;
  line-height: 1.5;
  text-align: center;
`;

const TeamWrapper = styled.section`
  text-align: center;
  margin-bottom: 10rem;
`;

const Phone = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem 2rem;

  color: ${({ theme: { colors } }) => colors.black};

  transition: ${({ theme }) => theme.transition};
  position: relative;
  z-index: 2;
  cursor: pointer;

  font-size: 1.6rem;

  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  transform: skewX(-4deg);

  &:after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: ${({ theme: { colors } }) => colors.yellow};

    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.boxShadow};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const Contacts = styled.ul`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const Contact = styled.li`
  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

const ContactLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.black};

  & > svg {
    fill: ${({ theme: { colors } }) => colors.white};
    transition: ${({ theme: { transition } }) => transition};
  }

  &:hover > svg {
    fill: ${({ theme: { colors } }) => colors.red};
  }
`;

const JoinUsButton = styled(Button)`
  color: ${props => props.theme.colors.white};
  margin-top: 3rem;

  &:after {
    background-color: ${props => props.theme.colors.black};
  }

  &:hover {
    color: ${props => props.theme.colors.white} !important;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 1.5rem;
  }
`;

const Decorated = styled.span`
  color: ${props => props.theme.colors.black};
  padding: 0.2rem 0.5rem;
  font-weight: bold;
  display: inline-block;
  margin: 0 0.2rem;
  position: relative;
  transform: skewX(-4deg);

  &:after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.yellow};

    transition: ${({ theme }) => theme.transition};
  }
`;

export const AboutUs: FC = () => {
  const [metrics, setMetrics] = useState<MetricsData | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);

  const EARTH_DIAMETER = 40000;

  useEffect(() => {
    api
      .getMetrics()
      .then(({ data }) => {
        setMetrics(data);
        setLoaded(true);
      })
      .catch(e => console.log(e));
  }, []);

  return (
    <>
      <Header activePage={pages.ABOUT_US} />
      <Title direction="ltr" inOneLine>
        О нас
      </Title>
      <MainContentWrapper>
        <MainContentContainer>
          <AboutUsWrapper>
            <AboutUsMain>
              <Paragraph>
                Мы, <Decorated>Squad UA Runners</Decorated>, команда энергичных
                и сильных бегунов, которые вдохновляются и вдохновляют бегом. Мы
                любим помогать друг другу, общаться, устраивать встречи,
                делиться эмоциями, победами и даже поражениями. Каждый день мы
                становимся лучше и стремимся быть прекрасным примером для
                подрастающего поколения. Мы намного больше, чем просто команда,
                мы настоящая семья!
              </Paragraph>
              <Paragraph>
                В июне 2018 годы мы создали нашу команду, а в октябре 2019 года
                зарегистрировали
                <Decorated>ГО «Сквад ЮА Раннерс»</Decorated>, и с тех пор нашей
                главной целью является развитие любительского бега в Украине, к
                чему мы уверенно идем!
              </Paragraph>
              <Paragraph>
                Совсем скоро нам исполнится два года! Пока нас
                <Decorated>
                  {loaded ? metrics!.numberOfParticipants : 0}
                </Decorated>
                участника из разных городов Украины и зарубежья, но мы растем и
                развиваемся. За все время мы набегали
                <Decorated>{loaded ? metrics!.kilometersOverall : 0}</Decorated>
                км , а это значит, что мы обогнули Землю аж
                <Decorated>
                  {loaded
                    ? Math.round(metrics!.kilometersOverall / EARTH_DIAMETER)
                    : 0}
                </Decorated>
                раз !
              </Paragraph>
              <Paragraph>
                Впечатляет, что
                <Decorated>
                  {loaded ? metrics!.numberOfParticipants : 0}
                </Decorated>
                человека могут сделать за меньше, чем два года? Тогда тебе к
                нам, давай творить новые беговые истории вместе!
              </Paragraph>
              <Paragraph>
                Нам <Decorated>не важно</Decorated>, в каком клубе ты
                тренируешься и в каком городе ты находишься, нам важны твоя
                любовь к бегу, твой бег и ты сам!
              </Paragraph>
              <Paragraph>
                Мы всегда открыты и приглашаем тебя присоединиться к нашей
                <Decorated>беговой семье</Decorated>!
              </Paragraph>
              <JoinUsButton
                target="_blank"
                href="https://linktr.ee/SquadUArunners"
              >
                Присоединиться
              </JoinUsButton>
            </AboutUsMain>
            <AboutUsSidebar>
              <UserPhoto avatar={Captain} />
              <NameCaption>Алексей Кочешков</NameCaption>
              <Paragraph>По всем вопросам, обращайтесь</Paragraph>
              <Phone href="tel:+380661389525">+380 66 138 9525</Phone>
              <Contacts>
                <Contact>
                  <ContactLink
                    href="https://t.me/Kocheshkovalexey"
                    target="_blank"
                  >
                    <FaTelegramPlane />
                  </ContactLink>
                </Contact>
                <Contact>
                  <ContactLink
                    href="https://www.facebook.com/kocheshkov.alexey/"
                    target="_blank"
                  >
                    <FaFacebookF />
                  </ContactLink>
                </Contact>
                <Contact>
                  <ContactLink
                    href="mailto:squaduarunners@gmail.com"
                    target="_blank"
                  >
                    <FiMail />
                  </ContactLink>
                </Contact>
              </Contacts>
            </AboutUsSidebar>
          </AboutUsWrapper>
        </MainContentContainer>
      </MainContentWrapper>
      <Footer />
    </>
  );
};
