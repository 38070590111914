import React, { FC, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  useWindowScroll,
  useThrottledFn,
  useWindowResize,
  useMediaQuery,
  Cancelable
} from 'beautiful-react-hooks';

import { Logo } from './logo';

import { ContainerBasedOnPaddings } from '../../ui/common';
import { breakpoints } from '../../ui/breakpoints';

import { MobileNavigation } from './../navigation/mobileNavigation';
import { Navigation } from './../navigation/navigation';
import { Scroller } from './scroller';

interface HeaderWrapperProps {
  /**
   * True if user scroll to bottom
   */
  isScrolled: boolean;
  /**
   * True if it's a mobile viewport
   */
  isMobileView?: boolean;
}

interface HeaderProps {
  /**
   * Active page name
   */
  activePage: number;
}

const HeaderWrapper = styled.div<HeaderWrapperProps>`
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;

  transition: ${props => props.theme.transition};
  overflow: hidden;

  height: ${({ isMobileView }) => (!isMobileView ? css`10rem` : css`8rem`)};

  box-shadow: ${props => props.theme.lightBoxShadow};
  background-color: ${({ isScrolled, theme: { colors } }) =>
    isScrolled ? colors.black : colors.yellow};
`;

const HeaderContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media screen and (max-width: ${breakpoints.s}px) {
    max-width: unset;
  }
`;

export const Header: FC<HeaderProps> = ({ activePage }) => {
  const HEADER_HEIGHT = 100;

  const [scrollY, setScrollY] = useState(window.pageYOffset);
  const [isScrolled, setScrolled] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobileView = useMediaQuery('(max-width: 768px)');
  const [isBurgerOpen, setBurgerOpen] = useState(false);

  const onBurgerClick = () => setBurgerOpen(!isBurgerOpen);

  const onWindowResizeHandler: Cancelable & Function = useThrottledFn(() =>
    setWidth(window.innerWidth)
  ) as Cancelable & Function;

  useWindowResize(onWindowResizeHandler);

  useWindowScroll(() => setScrollY(window.pageYOffset));

  useEffect(() => {
    setScrolled(scrollY > HEADER_HEIGHT);
    return () => onWindowResizeHandler.cancel();
  }, [onWindowResizeHandler, scrollY]);

  return (
    <>
      <HeaderWrapper
        id="top"
        isScrolled={isScrolled}
        isMobileView={isMobileView}
      >
        <Scroller isScrolled={isScrolled} />
        <HeaderContainer>
          <Logo isScrolled={isScrolled} hideText={width < breakpoints.s} />
          <Navigation
            activePage={activePage}
            isScrolled={isScrolled}
            isMobileView={isMobileView}
            onBurgerClick={onBurgerClick}
            isBurgerOpen={isBurgerOpen}
          />
        </HeaderContainer>
      </HeaderWrapper>
      {isMobileView && (
        <MobileNavigation activePage={activePage} isBurgerOpen={isBurgerOpen} closeBurger={() => setBurgerOpen(false)}/>
      )}
    </>
  );
};
