import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { AiOutlineUser } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import api from '../../services';
import { Loader } from '../common/loader';
import { SectionTitle } from '../common/section-title';
import { ContainerBasedOnPaddings } from '../../ui';
import { User as TopUser } from '../../interfaces';
import { breakpoints } from '../../ui/breakpoints';

const TopUsersWrapper = styled.section``;

const TopUsersContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    padding: 0;
    max-width: 100%;
  }
`;

const TopUsersList = styled.ul`
  width: 100%;
`;

const UserInfoWrapper = styled.li`
  position: relative;

  &::after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: calc(50%);
    z-index: -1;

    width: 100%;
    height: 5rem;

    transform: translate3d(0, -50%, 0) skewX(-4deg);
    background-color: #a1b6d1;

    ${({ theme }) =>
      css`
        transition: ${theme.transition};
      `};

    @media screen and (max-width: ${`${breakpoints.s}px`}) {
      transform: translate3d(0, -50%, 0);
    }
  }

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    & .index,
    & .team {
      display: none;
    }
  }

  &:hover {
    &::after {
      transform: translate3d(0, -50%, 0) skewX(0deg);
      opacity: 0.8;

      ${({ theme }) =>
        css`
          box-shadow: ${theme.boxShadow};
        `}
    }
  }

  &:nth-child(1)::after {
    background-color: #ffe929;
  }

  &:nth-child(2)::after {
    background-color: #ffb81e;
  }

  &:nth-child(3)::after {
    background-color: #ff9000;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const UserInfo = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;

  & > .wrapper {
    display: flex;
    align-items: center;

    > .index {
      margin-right: 2rem;
      width: 2rem;
    }

    & > span {
      margin-right: 2rem;
    }
  }
`;

const ViewAllMembersButton = styled(Link)`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1.5rem 4rem;

  color: ${({ theme: { colors } }) => colors.white};

  transition: ${({ theme }) => theme.transition};
  position: relative;
  z-index: 2;
  cursor: pointer;

  font-size: 1.6rem;

  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  transform: skewY(-4deg);

  &:after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: ${({ theme: { colors } }) => colors.black};

    transition: ${({ theme }) => theme.transition};
  }

  &:hover {
    color: ${({ theme: { colors } }) => colors.white} !important;
    transform: none;
    box-shadow: ${({ theme }) => theme.boxShadow};
  }

  &:active {
    transform: scale(0.98);
  }
`;

const PhotoPlaceholder = styled(AiOutlineUser)`
  font-size: 3rem;
  ${({ theme }) =>
    css`
      color: ${theme.colors.black};
    `};
`;

const UserPhoto = styled.img`
  width: 100%;
  max-width: 100%;
  object-fit: cover;
`;

const UserPhotoWrapper = styled.div<{ hasPhoto: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  margin-right: 2rem;
  transform: skewX(-4deg);
  overflow: hidden;

  ${({ theme }) =>
    css`
      box-shadow: ${theme.boxShadow};
    `};

  ${({ theme }) =>
    css`
      transition: ${theme.transition};
    `};

  ${({ hasPhoto, theme }) =>
    !hasPhoto
      ? css`
          background: ${theme.colors.white};
        `
      : css`
          background: none;
        `};
`;

export const TopUsers: FC = () => {
  const [topUsers, setTopUsers] = useState<TopUser[]>([]);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (loaded) {
      api.getTopMembers().then(({ data }) => {
        setTopUsers(data);
        setLoaded(false);
      });
    }
  }, [topUsers, loaded]);

  return (
    <TopUsersWrapper>
      <SectionTitle>Пользователи</SectionTitle>
      <TopUsersContainer>
        <TopUsersList>
          {loaded ? (
            <Loader />
          ) : (
            topUsers.map((topUser: TopUser, index) => {
              const {
                id,
                fullname,
                teamName,
                kilometersThisMonth,
                avatar
              } = topUser;
              const hasPhoto = avatar !== null;
              return (
                <UserInfoWrapper key={id}>
                  <UserInfo to={`/users/${id}/info`}>
                    <div className="wrapper">
                      <span className="index">{index + 1}</span>
                      <UserPhotoWrapper hasPhoto={hasPhoto}>
                        {avatar ? (
                          <UserPhoto src={avatar} />
                        ) : (
                          <PhotoPlaceholder />
                        )}
                      </UserPhotoWrapper>
                      <span>{fullname}</span>
                      <span className="team">{teamName}</span>
                    </div>
                    <span>{kilometersThisMonth} км</span>
                  </UserInfo>
                </UserInfoWrapper>
              );
            })
          )}
        </TopUsersList>
        <ViewAllMembersButton to="/users/all">
          Все пользователи
        </ViewAllMembersButton>
      </TopUsersContainer>
    </TopUsersWrapper>
  );
};
