import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { MdAccessTime, MdLocationOn } from 'react-icons/md';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { GiPathDistance } from 'react-icons/gi';
import { IoIosArrowForward } from 'react-icons/io';
import {
  Cancelable,
  useMediaQuery,
  useThrottledFn,
  useWindowResize
} from 'beautiful-react-hooks';
import moment from 'moment';

import { breakpoints } from '../../ui/breakpoints';

interface RaceProps {
  raceInfo: {
    date: string;
    time: string;
    distance: string;
    name: string;
    place: string;
  };
}

interface ExpandedProps {
  isExpanded: boolean;
}

const ContentWrapper = styled.div<ExpandedProps>`
  display: flex;
  width: 100%;

  @media screen and (max-width: ${breakpoints.m}px) {
    ${({ isExpanded }) =>
      isExpanded &&
      css`
        flex-direction: column;
      `};
  }
`;

const ToggleRaceWrapper = styled.div<ExpandedProps>`
  display: none;

  @media screen and (max-width: ${breakpoints.m}px) {
    display: flex;
    align-items: center;

    & > svg {
      transition: ${({ theme: { transition } }) => transition};
    }

    ${({ isExpanded }) =>
      isExpanded &&
      css`
        & > svg {
          transform: rotateZ(90deg);
        }
      `};
  }
`;

const RaceDataCaption = styled.h4<ExpandedProps>`
  display: none;
  font-weight: normal;

  @media screen and (max-width: ${breakpoints.m}px) {
    margin: 0;
    white-space: nowrap;

    ${({ isExpanded }) =>
      isExpanded &&
      css`
        display: block;
        min-width: 115px;
      `};
  }
`;

const RaceWrapper = styled.li<ExpandedProps>`
  display: flex;
  padding: 1.5rem 2rem;
  position: relative;

  &:after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme: { colors } }) => colors.black};
    transform: skewX(-4deg);
    transition: ${({ theme: { transition } }) => transition};
    z-index: -1;
  }

  &:hover {
    &:after {
      background-color: ${({ theme: { colors } }) => colors.yellow};
      border-color: transparent;
      box-shadow: ${({ theme: { boxShadow } }) => boxShadow};
    }
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: ${breakpoints.m}px) {
    align-items: flex-start;
  }
`;

const RaceData = styled.div<ExpandedProps>`
  display: flex;
  align-items: center;
  font-size: 2rem;

  & > svg {
    font-size: 2rem;
    margin-right: 1rem;
    fill: ${({ theme: { colors } }) => colors.grey};
  }

  &:nth-child(1) {
    width: 25%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &:nth-child(2) {
    width: calc(25% - 1.5rem);
    margin-left: 1.5rem;
  }

  &:nth-child(3) {
    width: 17%;
  }
  &:nth-child(4) {
    width: 20%;
  }
  &:nth-child(5) {
    width: 13%;
  }

  @media screen and (max-width: ${breakpoints.m}px) {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }

    &:nth-child(2) {
      margin-left: 0;
    }

    &:nth-child(1) {
      white-space: nowrap;
      width: 100%;
      flex-grow: 1;
      font-weight: bold;
    }

    ${({ isExpanded }) =>
      isExpanded &&
      css`
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: flex;
          width: 100%;
          align-items: center;
        }

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      `};
  }
  @media screen and (max-width: ${breakpoints.xs}px) {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      font-size: 16px;
    }
  }
`;

export const Race: FC<RaceProps> = ({
  raceInfo: { date, name, place, distance, time }
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const isTablet = useMediaQuery(`(max-width: ${breakpoints.m}px)`);
  const formattedDate = moment(date).format('DD/MM/YYYY');
  const allowToBeToggled = isTablet && isExpanded;
  const [width, setWidth] = useState(window.innerWidth);
  const [raceName, setRaceName] = useState(name);

  const MAX_RACE_NAME_LENGTH = 20;

  const onWindowResizeHandler: Cancelable & Function = useThrottledFn(() =>
    setWidth(window.innerWidth)
  ) as Cancelable & Function;

  useWindowResize(onWindowResizeHandler);

  useEffect(() => {
    if (width <= 550) {
      if (name.length > MAX_RACE_NAME_LENGTH) {
        setRaceName(name.slice(0, MAX_RACE_NAME_LENGTH - 3) + '...');
      }
    }
  }, [width, name]);

  const onClickHandler = () => {
    if (isTablet) {
      setExpanded(!isExpanded);
    }
  };

  return (
    <RaceWrapper isExpanded={allowToBeToggled} onClick={onClickHandler}>
      <ContentWrapper isExpanded={allowToBeToggled}>
        <RaceData isExpanded={allowToBeToggled}>{raceName}</RaceData>
        <RaceData isExpanded={allowToBeToggled}>
          <MdLocationOn />
          <RaceDataCaption isExpanded={allowToBeToggled}>
            Локация:
          </RaceDataCaption>
          {place}
        </RaceData>
        <RaceData isExpanded={allowToBeToggled}>
          <AiTwotoneCalendar />
          <RaceDataCaption isExpanded={allowToBeToggled}>Дата:</RaceDataCaption>
          {formattedDate}
        </RaceData>
        <RaceData isExpanded={allowToBeToggled}>
          <GiPathDistance />
          <RaceDataCaption isExpanded={allowToBeToggled}>
            Дистанция:
          </RaceDataCaption>
          {distance}
        </RaceData>
        <RaceData isExpanded={allowToBeToggled}>
          <MdAccessTime />
          <RaceDataCaption isExpanded={allowToBeToggled}>
            Время:
          </RaceDataCaption>
          {time}
        </RaceData>
      </ContentWrapper>
      <ToggleRaceWrapper isExpanded={allowToBeToggled}>
        <IoIosArrowForward />
      </ToggleRaceWrapper>
    </RaceWrapper>
  );
};
