import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface RotatefulProps {
  /**
   * Section direction
   */
  direction?: 'rtl' | 'ltr';
}

interface SectionTitleProps extends RotatefulProps {
  inOneLine?: boolean;
}

const SectionTitleWrapper = styled.div<RotatefulProps>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100vw;
  padding: 2rem 0;
  color: ${props => props.theme.colors.black};
  font-weight: bold;
  text-transform: uppercase;

  ${props =>
    props.direction === 'rtl' &&
    css`
      transform: skewY(4deg);
    `}

  ${props =>
    props.direction === 'ltr' &&
    css`
      transform: skewY(-4deg);
    `}

  &:before,
  &:after {
    display: block;
    content: '';

    height: 6px;
    width: 100%;
    background-color: ${props => props.theme.colors.black};
    position: relative;
  }
`;

const ContentWrapper = styled.div<SectionTitleProps>`
  padding: 2rem 4rem;
  font-size: 2rem;
  text-align: center;

  ${({ inOneLine }) =>
    inOneLine &&
    css`
      white-space: nowrap;
    `};

  ${props =>
    props.direction === 'rtl' &&
    css`
      transform: skewY(-4deg);
    `}

  ${props =>
    props.direction === 'ltr' &&
    css`
      transform: skewY(4deg);
    `}

  > svg {
    font-size: 6rem;
  }
`;

export const SectionTitle: FC<SectionTitleProps> = ({
  children,
  inOneLine,
  direction = 'rtl'
}) => (
  <SectionTitleWrapper direction={direction}>
    <ContentWrapper inOneLine={inOneLine} direction={direction}>
      {children}
    </ContentWrapper>
  </SectionTitleWrapper>
);
