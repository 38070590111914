import React, { FC } from 'react';
import styled from 'styled-components';
import { animateScroll } from 'react-scroll';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { SCROLL_CONFIGURATION } from '../../constants';

interface ScrollerProps {
  isScrolled: boolean;
}

const ScrollerWrapper = styled.div<ScrollerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  position: fixed;
  right: 30px;
  bottom: 30px;

  width: 50px;
  height: 50px;

  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.black};
  box-shadow: ${({ theme: { boxShadow } }) => boxShadow};
  transition: ${({ theme: { transition } }) => transition};

  font-size: 2rem;
  color: ${({ theme: { colors } }) => colors.white};
  cursor: pointer;

  & > svg {
    transition: ${({ theme: { transition } }) => transition};
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: none;

    & > svg {
      transform: translateY(-5px);
    }
  }

  transform: ${({ isScrolled }) => (isScrolled ? 'none' : 'translateX(100px)')};
`;

export const Scroller: FC<ScrollerProps> = ({ isScrolled }) => {
  const onClickHandler = () => animateScroll.scrollToTop(SCROLL_CONFIGURATION);

  return (
    <ScrollerWrapper onClick={onClickHandler} isScrolled={isScrolled}>
      <AiOutlineArrowUp />
    </ScrollerWrapper>
  );
};
