import { createGlobalStyle } from 'styled-components';
import { normalize } from './normalize';
import { vendor } from './vendor';
import { breakpoints } from './breakpoints';

export function fontFace(
  name: string,
  src: string,
  fontWeight: string | number = 'normal',
  fontStyle = 'normal'
) {
  /* eslint-disable */
  return `
        @font-face{
            font-family: "${name}";
            src: url(${require("../assets/fonts/" +
              src +
              ".woff")}) format("woff");
  
            font-style: ${fontStyle};
            font-weight: ${fontWeight};
        }
    `;
}

export const GlobalStyle = createGlobalStyle`
      /* From semantic-ui global styles */
      /* stylelint-disable */
      /* Border-Box */
      *,
      *::before,
      *::after {
          -webkit-box-sizing: inherit;
          box-sizing: inherit;
      }
  
      select,
      button,
      input,
      textarea {
          background: unset;
          border: 0;
          outline: 0;
          padding: 0;
      }
  
      html {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          --bg-one: #973999;
          --bg-two: #f8598b;
          --bg-three: #f7bf00;
          --bg-panel: #0c090d;
          --color-text: #fff;
          --color-fun: #c44394;
      }
      ${normalize};
      ${vendor};
      html,
      body {
          height: 100%;
      }
  
      html {
          box-sizing: border-box;
          font-size: 62.5%;
  
          @media only screen and (max-width: ${breakpoints.l}) {
              font-size: 55%;
          } 
      }
  
      body {
          overflow-x: hidden;
          font-family: "Roboto", Tahoma, Arial, Helvetica, sans-serif;
          scrollbar-width: 10px;
          scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0.1);
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 1.8rem;
          font-weight: 400;
      }
  
      //Reset default styles of a list
      ul {
          list-style: none;
          margin: 0;
          padding: 0;
      }
  
      a:link,
      a:active,
      a:visited {
          text-decoration: none;
      }
  
      /* Force Simple Scrollbars */
      ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 10px;
          height: 10px;
      }
  
      ::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 1);
          border-radius: 0px;
      }
  
      ::-webkit-scrollbar-thumb {
          cursor: pointer;
          border-radius: 5px;
          background: rgb(239, 43, 49, .7);
          -webkit-transition: color 0.2s ease;
          transition: color 0.2s ease;
      }
  
      ::-webkit-scrollbar-thumb:window-inactive {
          background: rgb(239, 43, 49, .7);
      }
  
      ::-webkit-scrollbar-thumb:hover {
          background: rgb(239, 43, 49, 1);
      }
      /*******************************
                  Headers
      *******************************/
  
      h1,
      h2,
      h3,
      h4,
      h5 {
          font-family: "BebasNeue", Arial, Helvetica, sans-serif;
          font-weight: bold;
      }
  
      h1:first-child,
      h2:first-child,
      h3:first-child,
      h4:first-child,
      h5:first-child {
          margin-top: 0em;
      }
  
      h1:last-child,
      h2:last-child,
      h3:last-child,
      h4:last-child,
      h5:last-child {
          margin-bottom: 0em;
      }
  
      /*******************************
                  Text
      *******************************/
  
      /*-------------------
              Links
      --------------------*/
  
      a,
      a:hover {
          color: inherit;
          text-decoration: none;
      }
  
      /*******************************
              Scrollbars
      *******************************/
  
      /*******************************
              Highlighting
      *******************************/
  
      /* Site */
  
      ::-webkit-selection {
          background-color: #ef2b31;
          color: rgba(0, 0, 0, 0.87);
      }
  
      ::-moz-selection {
          background-color: #ef2b31;
          color: rgba(0, 0, 0, 0.87);
      }
  
      ::selection {
          background-color: #ef2b31;
          color: rgba(0, 0, 0, 0.87);
      }
  
      /* Form */
  
      textarea::-webkit-selection,
      input::-webkit-selection {
          background-color: rgba(100, 100, 100, 0.4);
          color: rgba(0, 0, 0, 0.87);
      }
  
      textarea::-moz-selection,
      input::-moz-selection {
          background-color: rgba(100, 100, 100, 0.4);
          color: rgba(0, 0, 0, 0.87);
      }
  
      textarea::selection,
      input::selection {
          background-color: rgba(100, 100, 100, 0.4);
          color: rgba(0, 0, 0, 0.87);
      }
  
      /* stylelint-enable */
      #root {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
      }
  `;
