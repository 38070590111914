import { breakpoints } from '../ui/breakpoints';

export const COMMON_SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: breakpoints.m,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: breakpoints.xs,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
      }
    }
  ]
};

export const SCROLL_CONFIGURATION = {
  duration: 800,
  delay: 0,
  smooth: 'easeInOutQuart'
};

export const MOBILE_BREAKPOINT = 768;
