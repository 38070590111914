import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  FaTelegramPlane,
  FaInstagram,
  FaFacebook,
  FaPhone
} from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';
import {
  useWindowResize,
  useThrottledFn,
  Cancelable
} from 'beautiful-react-hooks';

import { ContainerBasedOnPaddings } from '../../ui/common';
import { breakpoints } from '../../ui/breakpoints';

import { Logo } from './logo';

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.colors.yellow};
  position: relative;
  padding: 1rem 0;

  @media (max-width: ${breakpoints.s}px) {
    padding: 0;
    padding-bottom: 2rem;
    background-color: ${props => props.theme.colors.black};
  }
`;

const Link = styled.a`
  display: block;
  transition: ${props => props.theme.transition};
  border-radius: 50%;
  font-size: 3rem;

  color: ${props => props.theme.colors.black};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.red};
  }

  @media (max-width: ${breakpoints.s}px) {
    color: ${props => props.theme.colors.yellow};
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.colors.black};
  margin: 0 0 1rem;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`;

const Column = styled.div<{ uiCentered?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ uiCentered }) =>
    uiCentered &&
    css`
      align-items: center;
    `};

  @media (max-width: ${breakpoints.s}px) {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
`;

const NavList = styled(List)`
  flex-flow: column nowrap;

  @media (max-width: ${breakpoints.s}px) {
    align-items: center;

    > li:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const FooterContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  justify-content: space-between;

  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: ${breakpoints.s}px) {
    flex-flow: column nowrap;
    justify-content: center;
  }
`;

const contactsCss = css`
  display: flex;
  align-items: center;
  font-weight: 400;

  font-size: 1.6rem;
  color: ${props => props.theme.colors.black};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.red};
  }

  > svg {
    font-size: 2rem;
    margin-right: 1rem;
  }

  @media (max-width: ${breakpoints.s}px) {
    color: ${props => props.theme.colors.yellow};
    font-size: 2rem;
  }
`;

const Mail = styled.a`
  ${contactsCss}
`;
const Telephone = styled.a`
  ${contactsCss}
`;

export const Footer: FC = () => {
  const [logoMode, setLogoMode] = useState<'desktop' | 'mobile'>('desktop');
  const [width, setWidth] = useState(window.innerWidth);


  const onWindowResizeHandler: Cancelable & Function = useThrottledFn(() =>
    setWidth(window.innerWidth)
  ) as Cancelable & Function;

  useWindowResize(onWindowResizeHandler);

  useEffect(() => {
    width < breakpoints.s ? setLogoMode('mobile') : setLogoMode('desktop');
    return () => onWindowResizeHandler.cancel();
  }, [onWindowResizeHandler, width]);

  return (
    <FooterWrapper>
      <FooterContainer>
        <Column>
          <Logo isScrolled={logoMode === 'desktop' ? false : true} />
        </Column>
        <Column>
          <Title>Контакты</Title>
          <NavList>
            <ListItem>
              <Mail href="mailto:squadua@squad.org.ua">
                <IoIosMail /> squadua@squad.org.ua
              </Mail>
            </ListItem>
            <ListItem>
              <Telephone href="tel:+380663048628">
                <FaPhone /> +380661389525
              </Telephone>
            </ListItem>
          </NavList>
        </Column>
        <Column>
          <Title>Мы в соц.сетях</Title>
          <List>
            <ListItem>
              <Link
                target="_blank"
                href="https://www.instagram.com/squaduarunners/"
              >
                <FaInstagram />
              </Link>
            </ListItem>
            <ListItem>
              <Link target="_blank" href="https://t.me/SquadUARunnersrules">
                <FaTelegramPlane />
              </Link>
            </ListItem>
            <ListItem>
              <Link
                target="_blank"
                href="https://www.facebook.com/SquadUARunners"
              >
                <FaFacebook />
              </Link>
            </ListItem>
          </List>
        </Column>
      </FooterContainer>
    </FooterWrapper>
  );
};
