import React, { FC } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Countdown } from '../common/countdown';
import { colors } from '../../theme';
import { breakpoints } from '../../ui/breakpoints';

const HeaderTitle = styled.h2`
  font-size: 4rem;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.yellow};
`;

const RaceHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  padding: 3.5rem 2rem;
  position: relative;

  &:before {
    display: flex;
    content: '';

    position: absolute;
    z-index: -1;

    transform: skewY(-4deg);

    height: 35rem;
    width: 200vw;
    left: -100%;
    top: -5rem;

    background-color: ${props => props.theme.colors.black};
  }

  @media (max-width: ${breakpoints.xs}px) {
    padding: 0 2rem;
  }
`;

const Banner = styled.img`
  display: block;
  width: 100%;
  height: 34rem;
  max-width: 100%;
  object-fit: cover;
  background-color: ${colors.white};

  position: relative;

  box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.3);

  @media (max-width: ${breakpoints.s}px) {
    height: 20rem;
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;

  margin-top: 2rem;
  font-weight: bold;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const City = styled.div`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 2rem;
`;

const RaceWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 800px;
  margin-top: 2rem;
  position: relative;

  &:after {
    display: flex;
    content: '';

    position: absolute;
    z-index: -1;

    transform: skewY(-4deg);

    height: 35rem;
    width: 200vw;
    left: -100%;
  }

  &:after {
    top: 30rem;
    background-color: ${props => props.theme.colors.yellow};
  }
`;

interface RaceCountdownProps {
  /**
   * Race banner
   */
  banner: string;
  /**
   * Race title
   */
  title: string;
  /**
   * Race city
   */
  city: string;
  /**
   * Race start date
   */
  startDate: string;
}

export const NearestRace: FC<RaceCountdownProps> = ({
  banner,
  title,
  city,
  startDate
}) => {
  const timeFormat = 'MM DD YYYY, h:mm a';
  const parsedDate = moment(startDate).format(timeFormat);

  return (
    <RaceWrapper>
      <RaceHeader>
        <HeaderTitle>До старта осталось</HeaderTitle>
        <Countdown timeTillDate={parsedDate} timeFormat={timeFormat} />
        <Banner src={banner} />
        <AdditionalInfo id="races">
          <Title>{title}</Title>
          <City>{city}</City>
        </AdditionalInfo>
      </RaceHeader>
    </RaceWrapper>
  );
};

NearestRace.defaultProps = {
  startDate: '8 31 2019, 9:00 am'
};
