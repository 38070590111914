import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { colors } from '../../theme';
import { NavigationProps } from './navigation';
import {SCROLL_CONFIGURATION} from "../../constants";

export enum pages {
  MAIN,
  ALL_USERS,
  ABOUT_US
}

const NavigationList = styled.ul<{ isMobileView?: boolean }>`
  display: flex;
  height: 100%;

  ${props =>
    props.isMobileView &&
    css`
      flex-direction: column;

      & > li > a {
        padding: 10px;
        color: ${colors.white};
        font-size: 2.5rem;

        &:after {
          display: none;
        }

        &:focus,
        &:hover {
          color: ${colors.yellow};
        }
      }
    `};
`;

const NavigationLink = styled(Link)<NavigationProps & { isActive: boolean }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: ${props =>
    props.isScrolled ? props.theme.colors.yellow : props.theme.colors.black};
  position: relative;
  transition: ${props => props.theme.transition};

  height: 100%;
  padding: 0 20px;

  cursor: pointer;

  &:after {
    display: block;
    content: '';

    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    width: 100%;
    height: 100%;

    background-color: ${props =>
      props.isScrolled ? props.theme.colors.yellow : props.theme.colors.black};
    clip-path: polygon(0 0, 100% 0%, 100% 70%, 0 85%);

    transform: translateY(-100%);
    will-change: transform;

    transition: ${props => props.theme.transition};
  }

  &:hover {
    color: ${props =>
      props.isScrolled ? props.theme.colors.black : props.theme.colors.white};
    &:after {
      transform: scaleY(1.3);
    }
  }

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.black};
      
      &:after {
      
    background-color: ${props => props.theme.colors.red};
        transform: scaleY(1.3);
      }
      
      &:hover {
        color: ${props =>
          props.isScrolled
            ? props.theme.colors.black
            : props.theme.colors.white};
      
        &:after {
          background-color: ${props =>
            props.isScrolled
              ? props.theme.colors.yellow
              : props.theme.colors.black};
        }
      }
  }`};
`;

const NavigationItem = styled.li`
  &:not(last-child) {
    margin-right: 20px;
  }
`;

interface NavigationListWrapperProps extends NavigationProps {
  className?: string;
}

export const NavigationListWrapper: FC<NavigationListWrapperProps> = ({
  isScrolled,
  isMobileView,
  className,
  activePage
}) => {
  const [hasScrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (!hasScrolled) {
      animateScroll.scrollToTop(SCROLL_CONFIGURATION);
      setScrolled(true);
    }
  }, [hasScrolled]);

  return (
    <NavigationList isMobileView={isMobileView} className={className}>
      <NavigationItem>
        <NavigationLink
          isActive={activePage === pages.MAIN}
          isScrolled={isScrolled}
          to="/"
        >
          Главная
        </NavigationLink>
      </NavigationItem>
      <NavigationItem>
        <NavigationLink
          isActive={activePage === pages.ALL_USERS}
          to="/users/all"
          isScrolled={isScrolled}
        >
          Все пользователи
        </NavigationLink>
      </NavigationItem>
      <NavigationItem>
        <NavigationLink
          isActive={activePage === pages.ABOUT_US}
          isScrolled={isScrolled}
          to="/about"
        >
          О нас
        </NavigationLink>
      </NavigationItem>
    </NavigationList>
  );
};
