import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { BurgerMenu } from '../common/burgerMenu';
import { NavigationListWrapper } from './navigationList';

const NavigationWrapper = styled.nav<NavigationProps>`
  height: ${({ isMobileView }) => (isMobileView ? css`10rem` : css`8rem`)};

  display: flex;
  align-items: center;
`;

export interface NavigationProps {
  /**
   * True if user scrolled page
   */
  isScrolled?: boolean;
  /**
   * True if it is a mobile viewport
   */
  isMobileView?: boolean;
  /**
   * On burger click menu handler
   */
  onBurgerClick?(): void;
  /**
   * True if someone clicked on burger
   */
  isBurgerOpen?: boolean;
  /**
   * Active page name
   */
  activePage?: number;
}

export const Navigation: FC<NavigationProps> = ({
  isScrolled,
  isMobileView,
  onBurgerClick,
  isBurgerOpen,
  activePage
}) => {

  return (
    <NavigationWrapper isMobileView={isMobileView}>
      <>
        {!isMobileView ? (
          <NavigationListWrapper
            isScrolled={isScrolled}
            isMobileView={isMobileView}
            activePage={activePage}
          />
        ) : (
          <BurgerMenu
            onClickHandler={onBurgerClick!}
            isOpen={isBurgerOpen!}
            isScrolled={isScrolled}
          />
        )}
      </>
    </NavigationWrapper>
  );
};
