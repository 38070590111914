import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '../../theme';

interface BurgerMenuComponent {
  /**
   * Is open state
   */
  isOpen: boolean;
  /**
   * Is user scroll
   */
  isScrolled?: boolean;
}

interface BurgerMenuProps extends BurgerMenuComponent {
  /**
   * On click handler
   */
  onClickHandler(): void;
}

const BurgerWrapper = styled.div<BurgerMenuComponent>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  width: 4rem;
  height: 3rem;
  transition: ${props => props.theme.transition};

  ${props =>
    props.isScrolled &&
    css`
      > div {
        background-color: ${colors.yellow};
      }
    `};

  ${props =>
    props.isOpen &&
    css`
      transform: none;
      & > div {
        &:nth-child(1) {
          width: 100%;
          transform: rotateZ(45deg) translateY(2rem);
        }
        &:nth-child(2) {
          transform: translateX(40px) skewY(0);
          opacity: 0;
        }
        &:nth-child(3) {
          width: 100%;
          transform: rotateZ(-45deg) translateY(-2rem);
        }
      }
    `};
`;

const Line = styled.div`
  height: 0.5rem;
  background-color: ${colors.black};
  transform: skewY(-4deg);
  width: 100%;

  transition: ${props => props.theme.transition};

  &:nth-child(2) {
    width: 80%;
  }
  &:nth-child(3) {
    width: 90%;
  }
`;

export const BurgerMenu: FC<BurgerMenuProps> = ({
  onClickHandler,
  isOpen,
  isScrolled
}) => {
  return (
    <BurgerWrapper
      isOpen={isOpen}
      onClick={onClickHandler}
      isScrolled={isScrolled}
    >
      <Line />
      <Line />
      <Line />
    </BurgerWrapper>
  );
};
