import React, { FC } from 'react';
import styled from 'styled-components';

import { ContainerBasedOnPaddings, Button } from '../../ui/common';
import GodunBackground from '../../assets/images/godun_bg.jpg';
import { breakpoints } from '../../ui/breakpoints';

const HeroWrapper = styled.div`
  width: 100vw;
  height: 100vh;

  position: relative;
  z-index: 1;

  background: url(${GodunBackground}) no-repeat center;
  background-size: cover;

  @media (max-width: ${breakpoints.m}px) {
    background: none;
    height: 100%;
    margin-bottom: 3rem;
  }
`;

const HeroContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  align-items: center;

  height: 100%;
  color: ${props => props.theme.colors.black};

  position: relative;
  z-index: 1;

  @media (max-width: ${breakpoints.s}px) {
    background: none;

    &:before,
    &:after {
      display: block;
      content: '';

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }

    &:after {
      z-index: -1;
      background-color: ${props => props.theme.colors.black};
      opacity: 0.5;
      clip-path: polygon(0 0, 100% 0%, 100% 86%, 0 93%);
    }

    &:before {
      background: url(${GodunBackground}) no-repeat;
      background-position: 75%;
      background-size: cover;
      z-index: -2;
      clip-path: polygon(0 0, 100% 0%, 100% 86%, 0 93%);
    }
  }
`;

const HeroDescription = styled.p`
  color: ${props => props.theme.colors.black};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 3rem;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoints.xs}px) {
    text-align: center;
    color: ${props => props.theme.colors.white};
  }
`;

const HeroContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  position: relative;
  padding: 10rem 0;
  z-index: 1;

  &:after {
    display: block;
    content: '';

    position: absolute;
    left: -300%;
    top: 0;
    z-index: -1;
    width: calc(400% + 2rem);
    height: 100%;

    border-top: 20px solid ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.yellow};

    transform: skewY(-4deg);
  }

  @media (max-width: ${breakpoints.s}px) {
    align-items: center;
    width: 100%;

    &:after {
      display: none;
    }
  }
`;

const ButtonMore = styled(Button)`
  align-self: start;

  @media (max-width: ${breakpoints.s}px) {
    align-self: center;

    position: absolute;
    bottom: -2rem;
  }
`;

const Bold = styled.span`
  font-weight: 900;
  color: ${props => props.theme.colors.black};

  @media (max-width: ${breakpoints.s}px) {
    color: ${props => props.theme.colors.yellow};
  }
`;

export const Hero: FC = () => {
  return (
    <HeroWrapper>
      <HeroContainer>
        <HeroContentWrapper>
          <HeroDescription>
            <Bold>Объединение людей</Bold> из Украины,
            <br /> которые
            <br /> по-настоящему
            <br />
            <Bold>любят</Bold> бег.
          </HeroDescription>
          <ButtonMore href="https://linktr.ee/SquadUArunners" target="__blank">
            Присоединиться
          </ButtonMore>
        </HeroContentWrapper>
      </HeroContainer>
    </HeroWrapper>
  );
};
