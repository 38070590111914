import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { compareAsc } from 'date-fns';

import { NearestRace } from './nearestRace';
import { Races, Race } from './races';
import { SectionTitle } from '../common/section-title';
import { Loader } from '../common/loader';
import api from '../../services';

const SectionWrapper = styled.div`
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  z-index: 1;
  margin-top: 1rem;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin-top: 1rem;
`;

export const RaceDetails: FC = () => {
  const [races, setRaces] = useState<Race[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [nearestRace, setNearestRace] = useState<Race>();

  const sortRaces = (races: Race[]) =>
    races.sort((raceLeft, raceRight) =>
      compareAsc(new Date(raceLeft.date), new Date(raceRight.date))
    );

  useEffect(() => {
    api
      .getRaces()
      .then(({ data }) => {
        const sortedRaces = sortRaces(data);
        setRaces(sortedRaces);
        setLoaded(true);
        setNearestRace(sortedRaces[0]);
      })
      .catch(e => console.log(e));
  }, []);

  return (
    <SectionWrapper>
      <Wrapper>
        <SectionTitle direction="ltr">Забеги</SectionTitle>
        <Content>
          {loaded && nearestRace ? (
            <NearestRace
              startDate={nearestRace.date}
              banner={nearestRace.imageUrl}
              title={nearestRace.name}
              city={nearestRace.city}
            />
          ) : (
            <Loader />
          )}
        </Content>
      </Wrapper>
      <Races races={races} isLoaded={loaded} />
    </SectionWrapper>
  );
};
