import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { GoOrganization } from 'react-icons/go';
import moment from 'moment';

import { breakpoints } from '../../ui/breakpoints';

interface RaceProps {
  name: string;
  city: string;
  date: string;
  imageUrl: string;
  url: string;
  numberOfParticipants: number;
}

const RaceWrapper = styled.a`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};

  overflow: hidden;

  &:hover .top {
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);
  }
`;

const Top = styled.div`
  height: 200px;
  overflow: hidden;
  box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.3);
  margin: 0 10px;

  transition: ${props => props.theme.transition};
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 20px;

  position: relative;
  color: ${props => props.theme.colors.black};

  @media (max-width: ${breakpoints.xs}px) {
    padding-bottom: 10px;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`;

const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.6rem;

  margin-top: 1rem;
`;

const Data = styled.span<{ uiBold?: boolean }>`
  display: flex;
  align-items: center;

  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  ${({ uiBold }) =>
    uiBold &&
    css`
      font-weight: bold;
    `};

  > svg {
    margin-right: 0.5rem;
  }
`;

export const Race: FC<RaceProps> = ({
  name,
  city,
  date,
  imageUrl,
  url,
  numberOfParticipants
}) => {
  try {
    new Date(date);
  } catch (e) {}
  const parsedDate = moment(date).format('DD.MM');

  return (
    <RaceWrapper href={url}>
      <Top className="top">
        <Image src={imageUrl} alt={name} />
      </Top>
      <Bottom>
        <Title>{name}</Title>
        <DataWrapper>
          <Data>{parsedDate}</Data>
          <Data>{city}</Data>
        </DataWrapper>
        <DataWrapper>
          <Data uiBold>{numberOfParticipants}</Data>
          <Data>
            <GoOrganization /> от Squad
          </Data>
        </DataWrapper>
      </Bottom>
    </RaceWrapper>
  );
};
