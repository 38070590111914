import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { AiOutlineUser, AiOutlineCaretDown } from 'react-icons/ai';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import moment from 'moment';
import 'moment/locale/ru';

import { Header } from '../components/common/header';
import { Footer } from '../components/common/footer';
import { ContainerBasedOnPaddings } from '../ui';
import { SectionTitle } from '../components/common/section-title';
import { User } from '../interfaces';
import api from '../services';
import { Loader } from '../components/common/loader';
import { compareNumbers, compareStrings } from '../helpers';
import { useForceUpdate } from '../hooks';

import { breakpoints } from '../ui/breakpoints';
import { pages } from '../components/navigation/navigationList';

enum SortCriterias {
  byKilometers = 'kilometers',
  byName = 'userNames',
  byTeam = 'teamNames'
}

type ComparatorType = 'number' | 'string';
type SortOrder = 'desc' | 'asc';

interface TableColumnsSortOrders {
  userNames: SortOrder;
  teamNames: SortOrder;
  kilometers: SortOrder;
}

const defaultSortOrder: TableColumnsSortOrders = {
  userNames: 'asc',
  kilometers: 'asc',
  teamNames: 'asc'
};

const AllMembersWrapper = styled.main`
  flex-grow: 1;
`;
const AllMembersContainer = styled(ContainerBasedOnPaddings)`
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    max-width: 100%;
  }
`;
const Title = styled(SectionTitle)`
  margin-top: 2rem;
`;
const Table = styled.div`
  margin: 3rem 0;
`;
const TableHeader = styled.ul`
  display: flex;
  margin-bottom: 2rem;

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    justify-content: space-between;
  }
`;
const TableHeaderBlock = styled.li<{ sortOrder?: SortOrder }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  transition: ${({ theme: { transition } }) => transition};
  cursor: pointer;

  &:nth-child(1) {
    min-width: 245px;
  }
  &:nth-child(2) {
    min-width: 500px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 160px;
  }

  &:hover {
    opacity: 0.5;
  }

  ${({ theme: { transition }, sortOrder }) =>
    sortOrder &&
    css`
      & > svg {
        margin-left: 1rem;
        transition: ${transition};
        transform: rotateZ(${sortOrder === 'desc' ? 'none' : '180deg'});
      }
    `}
   
  @media screen and (max-width: ${`${breakpoints.l}px`}) {
      &:nth-child(1) {
        min-width: 60px;
      }
      
       &:nth-child(2) {
        flex-grow: 1;
        min-width: 0;
      }
  }
  
  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: none;
    }
  
    &:nth-child(3) {
      min-width: 180px;
    }
  }
  
   @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }
  
   @media screen and (max-width: ${`${breakpoints.xs}px`}) {
   
    &:nth-child(4) {
      min-width: 0;
    }
  }
`;
const TableBody = styled.ul`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    align-items: space-between;
  }
`;
const TableRowWrapper = styled.li`
  &.transition-enter {
    opacity: 0;
    transform: translateY(-100x);
    transition: ${({ theme: { transition } }) => transition};
    transition-duration: 2s;
  }

  &.transition-enter-active {
    opacity: 1;
    transform: none;
    transition: ${({ theme: { transition } }) => transition};
    transition-duration: 2s;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.black};
  }
`;
const TableRow = styled(Link)`
  display: flex;
  width: 100%;
  padding: 0.5rem;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.yellow};
  }
`;
const TableColumn = styled.div`
  display: flex;
  align-items: center;

  &:nth-child(1) {
    padding-left: 0.5rem;
    min-width: 160px;
  }
  &:nth-child(2) {
    min-width: 580px;
  }
  &:nth-child(3) {
    min-width: 275px;
  }
  &:nth-child(4) {
    min-width: 155px;
  }

  @media screen and (max-width: ${`${breakpoints.l}px`}) {
    &:nth-child(1) {
      min-width: 60px;
    }
    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
    }
  }

  @media screen and (max-width: ${`${breakpoints.m}px`}) {
    &:nth-child(1) {
      display: none;
    }

    &:nth-child(2) {
      flex-grow: 1;
      min-width: 0;
    }

    &:nth-child(3) {
      min-width: 180px;
    }
  }
  @media screen and (max-width: 670px) {
    &:nth-child(3) {
      display: none;
    }
  }

  @media screen and (max-width: ${`${breakpoints.xs}px`}) {
    &:nth-child(4) {
      min-width: 0;
    }
  }

  @media screen and (max-width: 375px) {
    &:nth-child(2) > .fullname {
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
`;
const PhotoPlaceholder = styled(AiOutlineUser)`
  font-size: 3rem;
  ${({ theme }) =>
    css`
      color: ${theme.colors.black};
    `};
`;
const UserPhoto = styled.img`
  width: 100%;
  max-width: 100%;
  object-fit: cover;
`;
const UserPhotoWrapper = styled.div<{ hasPhoto: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  margin-right: 2rem;
  transform: skewX(-4deg);
  overflow: hidden;

  box-shadow: ${({ theme: { boxShadow } }) => boxShadow};
  transition: ${({ theme: { transition } }) => transition};

  background: ${({ hasPhoto, theme: { colors } }) =>
    !hasPhoto ? colors.white : 'none'};

  @media screen and (max-width: ${`${breakpoints.s}px`}) {
    width: 4rem;
    height: 4rem;
  }
`;

export const AllUsers: FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [sortOrders, setSortOrders] = useState<TableColumnsSortOrders>(
    defaultSortOrder
  );
  const [loaded, setLoaded] = useState(true);
  const forceUpdate = useForceUpdate();

  const currentMonth = moment()
    .locale('ru')
    .format('MMMM')
    .toLowerCase();

  useEffect(() => {
    if (loaded) {
      api.getAllMembers().then(({ data }) => {
        setUsers(data);
        setLoaded(false);
      });
    }
  });

  const getComparator = (
    order: SortOrder,
    type: ComparatorType
  ): ((a: any, b: any) => number) => {
    if (order === 'desc') {
      return type === 'number'
        ? (a: number, b: number): number => compareNumbers(a, b)
        : (a: string, b: string): number => compareStrings(a, b);
    } else {
      return type === 'number'
        ? (a: number, b: number): number => compareNumbers(b, a)
        : (a: string, b: string): number => compareStrings(b, a);
    }
  };

  const sortBy = (sortCriteria: SortCriterias) => {
    const order = sortOrders[sortCriteria];
    const updatedSortOrders = {
      ...sortOrders,
      [sortCriteria]: order === 'desc' ? 'asc' : 'desc'
    };
    const comparatorType =
      sortCriteria === SortCriterias.byKilometers ? 'number' : 'string';
    const comparator = getComparator(order, comparatorType);

    // eslint-disable-next-line
    users.sort((u1: User, u2: User): number => {
      const { byKilometers, byName, byTeam } = SortCriterias;
      switch (sortCriteria) {
        case byKilometers:
          return comparator(u1.kilometersThisMonth, u2.kilometersThisMonth);
        case byTeam:
          return comparator(u1.teamName, u2.teamName);
        case byName:
          return comparator(u1.fullname, u2.fullname);
      }
    });

    setSortOrders(updatedSortOrders);
    forceUpdate();
  };

  const sortByName = () => sortBy(SortCriterias.byName);
  const sortByTeam = () => sortBy(SortCriterias.byTeam);
  const sortByKilometers = () => sortBy(SortCriterias.byKilometers);

  return (
    <>
      <Header activePage={pages.ALL_USERS} />
      <AllMembersWrapper>
        <Title direction="ltr">Члены бегосемьи</Title>
        <AllMembersContainer>
          <Table>
            <TableHeader>
              <TableHeaderBlock>№</TableHeaderBlock>
              <TableHeaderBlock
                onClick={sortByName}
                sortOrder={sortOrders.userNames}
              >
                Имя <AiOutlineCaretDown />
              </TableHeaderBlock>
              <TableHeaderBlock
                onClick={sortByTeam}
                sortOrder={sortOrders.teamNames}
              >
                Команда <AiOutlineCaretDown />
              </TableHeaderBlock>
              <TableHeaderBlock
                onClick={sortByKilometers}
                sortOrder={sortOrders.kilometers}
              >
                Км за {currentMonth} <AiOutlineCaretDown />
              </TableHeaderBlock>
            </TableHeader>
            {loaded ? (
              <Loader />
            ) : (
              <TransitionGroup component={TableBody}>
                {users.map((user, index) => {
                  const {
                    id,
                    fullname,
                    teamName,
                    kilometersThisMonth,
                    avatar
                  } = user;
                  const hasAvatar = avatar !== null;
                  return (
                    <CSSTransition
                      key={id}
                      appear={false}
                      enter={true}
                      exit={false}
                      classNames="transition"
                      timeout={1000}
                    >
                      <TableRowWrapper key={id}>
                        <TableRow to={`/users/${id}/info`}>
                          <TableColumn>{index + 1}</TableColumn>
                          <TableColumn>
                            <UserPhotoWrapper hasPhoto={hasAvatar}>
                              {avatar ? (
                                <UserPhoto src={avatar} />
                              ) : (
                                <PhotoPlaceholder />
                              )}
                            </UserPhotoWrapper>
                            <span className="fullname">{fullname}</span>
                          </TableColumn>
                          <TableColumn>{teamName}</TableColumn>
                          <TableColumn>{kilometersThisMonth} км </TableColumn>
                        </TableRow>
                      </TableRowWrapper>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            )}
          </Table>
        </AllMembersContainer>
      </AllMembersWrapper>
      <Footer />
    </>
  );
};
