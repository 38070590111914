import React, { FC } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import { Race } from './race';
import { Loader } from '../common/loader';

import { ContainerBasedOnPaddings } from '../../ui/common';
import { breakpoints } from '../../ui/breakpoints';
import { COMMON_SLIDER_SETTINGS } from '../../constants';

const RacesWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;

  @media (max-width: ${breakpoints.xs}px) {
    margin-top: 1rem;
  }
`;

const RacesContainer = styled(ContainerBasedOnPaddings)`
  width: 100%;
`;

const RacesHolder = styled.div`
  position: relative;
  z-index: 1;

  margin-bottom: 5rem;
  width: 100%;

  & .card {
    &__title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      line-height: 1;
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: ${breakpoints.xs}px) {
    margin-bottom: 10rem;
  }
`;

export interface Race {
  /**
   * Race id
   */
  id: number;
  /**
   * Race name
   */
  name: string;
  /**
   * Race holder city
   */
  city: string;
  /**
   * Race start date
   */
  date: string;
  /**
   * Race banner
   */
  imageUrl: string;
  /**
   * Race participants number
   */
  numberOfParticipants: number;
  /**
   * Url for registrations
   */
  url: string;
}

interface RacesProps {
  /**
   * All races
   */
  races: Race[];
  /**
   * Is races loaded
   */
  isLoaded: boolean;
}

export const Races: FC<RacesProps> = ({ races, isLoaded }) => {
  const settings = {
    ...COMMON_SLIDER_SETTINGS
  };

  const formatRaces = (races: Race[]) =>
    races &&
    races.map(
      ({
        id,
        name,
        city,
        date,
        imageUrl,
        url,
        numberOfParticipants
      }): JSX.Element => (
        <Race
          key={id}
          name={name}
          city={city}
          date={date}
          imageUrl={imageUrl}
          url={url}
          numberOfParticipants={numberOfParticipants}
        />
      )
    );

  return (
    <RacesWrapper>
      <RacesContainer>
        <RacesHolder>
          {!isLoaded && !races ? (
            <Loader />
          ) : (
            <Slider {...settings}>{formatRaces(races)}</Slider>
          )}
        </RacesHolder>
      </RacesContainer>
    </RacesWrapper>
  );
};
