import React, { useEffect, useState, FC } from 'react';
import styled from 'styled-components';

import { ContainerBasedOnPaddings, Button } from '../../ui/common';
import { breakpoints } from '../../ui/breakpoints';
import api from '../../services';

import { Loader } from '../common/loader';
import { SectionTitle } from '../common/section-title';

export interface MetricsData {
  numberOfParticipants: number;
  numberOfCities: number;
  kilometersThisMonth: number;
  kilometersOverall: number;
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  &:after {
    display: block;
    content: '';

    background-color: ${props => props.theme.colors.yellow};

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    z-index: -1;

    transform: skewY(4deg);
  }
`;

const MetricsContainer = styled(ContainerBasedOnPaddings)`
  display: flex;
  justify-content: center;
`;

const MetricsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;

  padding: 5rem 10rem;
  margin: 5rem 0;

  position: relative;
  z-index: 1;

  width: 100%;

  &:after {
    display: block;
    content: '';

    background-color: ${props => props.theme.colors.black};

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    transform: skewY(-4deg);
  }

  @media (max-width: ${breakpoints.m}px) {
    margin: 0;

    &:after {
      transform: skewY(4deg);
    }
  }
`;

const Title = styled.h2`
  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
  font-weight: 300;
  margin-bottom: 0;
  font-size: 2rem;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.6rem;
    text-align: center;
  }
`;

const Value = styled.div`
  color: ${props => props.theme.colors.yellow};
  font-weight: bold;
  font-size: 4rem;
  margin: 0 1rem;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 3rem;
    white-space: nowrap;
  }
`;

const Metric = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.yellow};

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media (max-width: ${breakpoints.m}px) {
    flex-flow: column nowrap;
  }
`;

const JoinUsButton = styled(Button)`
  color: ${props => props.theme.colors.black};
  margin-top: 3rem;

  &:after {
    background-color: ${props => props.theme.colors.yellow};
  }

  &:hover {
    color: ${props => props.theme.colors.black} !important;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 1.5rem;
  }
`;

export const Metrics: FC = () => {
  const [metrics, setMetrics] = useState<MetricsData | undefined>(undefined);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    api
      .getMetrics()
      .then(({ data }) => {
        setMetrics(data);
        setLoaded(true);
      })
      .catch(e => console.log(e));
  }, []);

  return (
    <Wrapper id="about">
      <SectionTitle>Статистика</SectionTitle>
      <MetricsContainer>
        <MetricsWrapper>
          <Metric>
            <Title>
              ВСЕГО УЧАСТНИКОВ
              <Value>
                {loaded ? metrics!.numberOfParticipants : <Loader />}
              </Value>
            </Title>
          </Metric>
          <Metric>
            <Title>
              ИЗ
              <Value>{loaded ? metrics!.numberOfCities : <Loader />}</Value>
              ГОРОДОВ Украины
            </Title>
          </Metric>
          <Metric>
            <Title>
              ЗА ВСЁ ВРЕМЯ
              <Value>
                {loaded ? `${metrics!.kilometersOverall}` : <Loader />}
              </Value>
              км
            </Title>
          </Metric>
          <Metric>
            <Title>
              ЗА ЭТОТ МЕСЯЦ
              <Value>
                {loaded ? `${metrics!.kilometersThisMonth}` : <Loader />}
              </Value>
              км
            </Title>
          </Metric>
          <JoinUsButton  target="_blank" href="https://linktr.ee/SquadUArunners">
            Присоединиться
          </JoinUsButton>
        </MetricsWrapper>
      </MetricsContainer>
    </Wrapper>
  );
};
