import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import {
  FaTelegramPlane,
  FaInstagram,
  FaFacebook,
  FaPhone
} from 'react-icons/fa';
import { IoIosMail } from 'react-icons/io';

import { colors } from '../../theme';
import { NavigationListWrapper } from './navigationList';

const contactsCss = css`
  display: flex;
  align-items: center;
  font-weight: 400;

  margin-bottom: 1rem;

  font-size: 1.6rem;
  color: ${props => props.theme.colors.yellow};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.red};
  }

  > svg {
    font-size: 2rem;
    margin-right: 1rem;
  }
`;

const Mail = styled.a`
  ${contactsCss}
`;
const Telephone = styled.a`
  ${contactsCss}
`;

const MobileNavigationWrapper = styled.div<{ isBurgerOpen: boolean }>`
  display: flex;
  flex-direction: column;

  background-color: ${colors.black};
  border-bottom: 4px solid ${colors.yellow};

  position: fixed;
  top: 8rem;
  right: 0;

  transform: none;
  padding: 5rem 2rem;

  transition: ${props => props.theme.transition};
  width: 80%;

  z-index: 1000;

  ${props =>
    !props.isBurgerOpen &&
    css`
      transform: translateX(100%);
    `};
`;

const Socials = styled.div`
  display: flex;
`;

const SocialsListItem = styled.li`
  display: flex;
  &:not(:last-child) {
    margin-right: 1.5rem;
  }
`;

const SocialLink = styled.a`
  display: block;
  transition: ${props => props.theme.transition};
  border-radius: 50%;
  font-size: 3rem;
  color: ${props => props.theme.colors.yellow};
  cursor: pointer;

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.red};
  }
`;

const Title = styled.h2`
  display: flex;
  align-items: center;

  color: ${props => props.theme.colors.white};
  text-transform: uppercase;
  margin: 2rem 0 1rem 0;
  font-size: 1.6rem;

  > svg {
    margin-left: 1rem;
  }
`;

const Contacts = styled.div``;

const MobileNavList = styled(NavigationListWrapper)`
  height: auto;

  > li > a {
    margin-left: 2rem;
    font-size: 2rem;
  }
`;

interface MobileNavigationProps {
  isBurgerOpen: boolean;
  activePage: number;
  closeBurger(): void;
}

export const MobileNavigation: FC<MobileNavigationProps> = ({
  isBurgerOpen,
  activePage,
  closeBurger
}) => {
  const onClickHandler = () => closeBurger();

  return (
    <MobileNavigationWrapper
      isBurgerOpen={isBurgerOpen}
      onClick={onClickHandler}
    >
      <MobileNavList activePage={activePage} isMobileView={true} />
      <Title>Контакты</Title>
      <Contacts>
        <Mail target="_blank" href="mailto:squadua@squad.org.ua">
          <IoIosMail /> squadua@squad.org.ua
        </Mail>
        <Telephone target="_blank" href="tel:+380663048628">
          <FaPhone /> +380661389525
        </Telephone>
      </Contacts>
      <Title>Мы в соц.сетях</Title>
      <Socials>
        <SocialsListItem>
          <SocialLink
            target="_blank"
            href="https://www.facebook.com/SquadUARunners"
          >
            <FaFacebook />
          </SocialLink>
        </SocialsListItem>
        <SocialsListItem>
          <SocialLink
            target="_blank"
            href="https://www.instagram.com/squaduarunners/"
          >
            <FaInstagram />
          </SocialLink>
        </SocialsListItem>
        <SocialsListItem>
          <SocialLink target="_blank" href="https://t.me/SquadUARunnersrules">
            <FaTelegramPlane />
          </SocialLink>
        </SocialsListItem>
      </Socials>
    </MobileNavigationWrapper>
  );
};
