import React, { FC } from 'react';
import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';

export interface InstaPostProps {
  url: string;
  link: string;
}

const Post = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: none;
  height: 320px;

  position: relative;
  box-shadow: 0 10px 10px 1px rgba(0, 0, 0, 0.3);
  transition: ${props => props.theme.transition};

  &:hover,
  &:focus {
    box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1);

    & svg {
      transition: ${props => props.theme.transition};
      transition-delay: 0.1s;
      transform: none;
      opacity: 1;
    }
  }
`;

const Image = styled.img`
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  background-color: rgba(0, 0, 0, 0.4);

  opacity: 0;
  transition: ${props => props.theme.transition};

  &:hover {
    opacity: 1;
  }
`;

const OverlayInfo = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;

  color: ${props => props.theme.colors.white};

  > svg {
    transform: translateY(10px);
    opacity: 0;
    margin-right: 1rem;
  }
`;

export const InstaPost: FC<InstaPostProps> = ({ url, link }) => {
  return (
    <Post href={link} target="__blank">
      <Image src={url} alt="Insta post main" />
      <Overlay>
        <OverlayInfo>
          <FaArrowUp />
          Посмотреть
        </OverlayInfo>
      </Overlay>
    </Post>
  );
};
